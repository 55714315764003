$(document).ready(function(){
  // $('.slick').slick({
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	autoplay: true,
	// 	autoplaySpeed: 5000,
	// 	dots:true,
	// 	arrows:false
	// });

	$(".sticky").stickyFooter();

	$("a[rel^='prettyPhoto']").prettyPhoto({
		animation_speed: 'fast', /* fast/slow/normal */
		slideshow: 5000, /* false OR interval time in ms */
		autoplay_slideshow: false, /* true/false */
		opacity: 0.80, /* Value between 0 and 1 */
		show_title: false, /* true/false */
		allow_resize: true, /* Resize the photos bigger than viewport. true/false */
		default_width: 800,
		default_height: 600,
		counter_separator_label: '/', /* The separator for the gallery counter 1 "of" 2 */
		theme: 'facebook', /* light_rounded / dark_rounded / light_square / dark_square / facebook */
		horizontal_padding: 20, /* The padding on each side of the picture */
		hideflash: false, /* Hides all the flash object on a page, set to TRUE if flash appears over prettyPhoto */
		wmode: 'opaque', /* Set the flash wmode attribute */
		autoplay: true, /* Automatically start videos: True/False */
		modal: false, /* If set to true, only the close button will close the window */
		deeplinking: true, /* Allow prettyPhoto to update the url to enable deeplinking. */
		overlay_gallery: true, /* If set to true, a gallery will overlay the fullscreen image on mouse over */
		keyboard_shortcuts: true, /* Set to false if you open forms inside prettyPhoto */
		social_tools: false /* html or false to disable */
	});

  $(".menu__list li.parent").mouseenter(function(){$(this).addClass('open')});
  $(".menu__list li.parent").mouseleave(function(){$(this).removeClass('open')});

	$(window).scroll(function() {
		var top = $('body').scrollTop();
		var topMax = $('.header__lang').outerHeight();
		if (top <= topMax) {
			$('.header').css({top: -top+"px"});
		} else {
			$('.header').css({top: -topMax+"px"});
		}
	});
});
